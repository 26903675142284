import { constants } from '../constants'

// Saved in store.user
// const initialState = null

// Returns userdata if available, else return null
function tryFetchingUserFromLocalStorage() {
  let user = ''

  try {
    user = localStorage.getItem('user')
    user = JSON.parse(user)
  } catch (e) {
    return null
  }

  if (
    user &&
    typeof user === 'object' &&
    user.id &&
    user.token &&
    user.displayName &&
    user.localStorageExpires
  ) {
    // Check expire Date
    const date = new Date(user.localStorageExpires)
    if (Date.now() > date) {
      localStorage.removeItem('user')
      return null
    }
    return user
  }

  return null
}

const initialState = tryFetchingUserFromLocalStorage()

export const update = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case constants.RECEIVE_USERDATA:
      if (action.payload && action.payload.id) {
        // TODO: get from payload
        return {
          ...action.payload,
        }
      }

      return state
    // Logout
    case constants.CLEAR_USERDATA:
      return null
    default:
      return state
  }
}
