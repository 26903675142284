import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppAction, AppState } from './types'

const initialState: AppState = {
  flashMsg: undefined, // Used to control showing flash messages
  loginModal: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setFlashMsg: (state, { payload }: PayloadAction<AppAction>) => {
      if (payload.msg && payload.msg.length) {
        state.flashMsg = {
          msg: payload.msg,
          style: payload.style,
          data: payload.data,
          sticky: payload.sticky,
          action: payload.data?.action,
        }
      } else {
        state.flashMsg = undefined
      }
    },
    clearFlashMsg: (state) => {
      state.flashMsg = undefined
    },
    setLoginModal: (state, { payload }: PayloadAction<boolean>) => {
      state.loginModal = payload
    },
  },
})

export const { setFlashMsg, clearFlashMsg, setLoginModal } = appSlice.actions

export default appSlice.reducer
