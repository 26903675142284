import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import './LoginModal.scss'
import { Close } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import { RootState } from '../../store'
import { setLoginModal } from '../../store/app'
import { getAdLoginUrl, getSuomiFiLoginUrl } from '../../utils/user'

const LoginModal: React.FC = () => {
  const open = useSelector((state: RootState) => state.app.loginModal)
  const dispatch = useDispatch()
  const intl = useIntl()

  const cancel = () => dispatch(setLoginModal(false))

  return (
    <Dialog open={open} onClose={cancel} transitionDuration={0}>
      <DialogTitle>
        <span style={{ marginTop: 8 }}>
          {intl.formatMessage({ id: 'login' })}
        </span>
        <IconButton onClick={cancel}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p>{intl.formatMessage({ id: 'login-modal-info' })}</p>
      </DialogContent>
      <DialogActions>
        <a href={getAdLoginUrl()}>
          <Button
            style={{ paddingLeft: '32px', paddingRight: '32px' }}
            variant="outlined"
          >
            {intl.formatMessage({ id: 'espoo-ad-login' })}
          </Button>
        </a>
        <a href={getSuomiFiLoginUrl()}>
          <Button
            style={{ paddingLeft: '32px', paddingRight: '32px' }}
            variant="contained"
            color="primary"
          >
            {intl.formatMessage({ id: 'suomifi-login' })}
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  )
}

export default LoginModal
