import { Button } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HelMaterialTheme } from '../../themes/material-ui'
import { setLoginModal } from '../../store/app'
import { useDispatch } from 'react-redux'
import { features } from '../../utils/featureFlags'
import { getAdLoginUrl } from '../../utils/user'

const LoginButton: React.FC = () => {
  const dispatch = useDispatch()
  const buttonComponent = features.multiLogin ? (
    <Button
      onClick={() => dispatch(setLoginModal(true))}
      style={{ color: HelMaterialTheme.palette.primary.contrastText }}
      startIcon={<Person />}
    >
      <FormattedMessage id="login" />
    </Button>
  ) : (
    <Button
      href={getAdLoginUrl()}
      style={{ color: HelMaterialTheme.palette.primary.contrastText }}
      startIcon={<Person />}
    >
      <FormattedMessage id="login" />
    </Button>
  )
  return buttonComponent
}

export default LoginButton
