import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { OrganizationAccessRequestInfo, UserAccessInfo } from './UserManagement'
import { Search } from '@material-ui/icons'

import './UsersTable.scss'
import { useClient } from '../../api/clientContext'
import { useDispatch } from 'react-redux'
import { setFlashMsg } from '../../store/app/index'

export interface UsersTableColumn {
  id: string
  translationId: string
}

interface UsersTableProps {
  data: UserAccessInfo[] | OrganizationAccessRequestInfo[]
  loading: boolean // TODO: Should you use redux editor/setLoading instead?
  columns: UsersTableColumn[]
  headerId: string
  dirtyState?: boolean
  setDirtyState?: (b: boolean | undefined) => void
  setLoadingUsers?: (b: boolean) => void // TODO: Should you use redux editor/setLoading instead?
  setTableData?: (data: OrganizationAccessRequestInfo[]) => void
}

const isOarInfo = (
  data: UserAccessInfo | OrganizationAccessRequestInfo
): data is OrganizationAccessRequestInfo => {
  return 'id' in data
}

const UsersTable: React.FC<UsersTableProps> = ({
  data: receivedData,
  loading,
  columns,
  headerId,
  dirtyState,
  setDirtyState,
  setLoadingUsers,
  setTableData,
}) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [searchQuery, setSearchQuery] = useState('')
  const [shownData, setShownData] = useState<
    (UserAccessInfo | OrganizationAccessRequestInfo)[]
  >([])
  const [OARLoading, setOARLoading] = useState(false)
  const intl = useIntl()
  const client = useClient()
  const dispatch = useDispatch()

  useEffect(() => {
    const dataToBeShown = !searchQuery
      ? receivedData
      : receivedData?.filter((dataRow) =>
          Object.values(dataRow).some((property) =>
            property?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
    setShownData(dataToBeShown)
    setPage(0)
  }, [receivedData, searchQuery])

  const pageSizeOptions = [10, 25, 50, 100]
  const dataSliceStart = page * pageSize
  const dataSliceEnd = dataSliceStart + pageSize

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPageSize(+event.target.value)
    setPage(0)
  }

  const handleProcessOrganizationAccessRequest = async (
    action: string,
    id: string
  ): Promise<void> => {
    if (!client) {
      console.warn('Client not initialized')
      return
    }

    setOARLoading(true)

    try {
      await client.put(`organizationaccessrequest/${id}`, {
        action_type: action,
      })
      dispatch(
        setFlashMsg({
          msg: `organization-access-request-${action.toLowerCase()}ed`,
          style: 'message',
          sticky: false,
        })
      )
      if (setTableData) {
        setTableData(
          receivedData.filter(isOarInfo).filter((oar) => oar.id !== id)
        )
      }
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'generic-error-message',
          style: 'error',
          sticky: false,
        })
      )
    }

    setOARLoading(false)
    if (setDirtyState && setLoadingUsers) {
      setDirtyState(!dirtyState)
      setLoadingUsers(true)
    }
  }

  return (
    <>
      <h2>{intl.formatMessage({ id: headerId })}</h2>
      <TextField
        className="users-table-search"
        placeholder={intl.formatMessage({ id: 'search' })}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="disabled" />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <FormattedMessage id={column.translationId} />
                </TableCell>
              ))}
              {headerId === 'user-management-oars' && (
                <TableCell key="oar-actions-empty-head-column" />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress style={{ margin: '10px 0' }} />
                </TableCell>
              </TableRow>
            ) : (
              shownData
                .slice(dataSliceStart, dataSliceEnd)
                .map((row: Record<string, string>) => (
                  <TableRow hover key={row.userName ?? row.id}>
                    {columns.map((column) => {
                      // Jos organisaatioita paljon niin miten rivitetään etc?
                      const value = row[column.id]
                      return (
                        <TableCell key={row.userName + column.id}>
                          {value}
                        </TableCell>
                      )
                    })}
                    {headerId === 'user-management-oars' && (
                      <TableCell>
                        <div className="oar-buttons">
                          <Button
                            variant="outlined"
                            disabled={OARLoading}
                            onClick={() =>
                              handleProcessOrganizationAccessRequest(
                                'REJECT',
                                row.id
                              )
                            }
                            endIcon={
                              OARLoading && (
                                <CircularProgress color="inherit" size={25} />
                              )
                            }
                          >
                            {intl.formatMessage({
                              id: 'reject-organization-access-request',
                            })}
                          </Button>
                          <Button
                            variant="contained"
                            disabled={OARLoading}
                            onClick={() =>
                              handleProcessOrganizationAccessRequest(
                                'GRANT',
                                row.id
                              )
                            }
                            endIcon={
                              OARLoading && (
                                <CircularProgress color="inherit" size={25} />
                              )
                            }
                            color="primary"
                          >
                            {intl.formatMessage({
                              id: 'grant-organization-access-request',
                            })}
                          </Button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={pageSizeOptions}
                count={shownData.length}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(_event: unknown, newPage: number): void =>
                  setPage(newPage)
                }
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default UsersTable
